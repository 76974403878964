import React from "react";
import "../css/contactus.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function ContactUs() {
  return (
    <>
      <Navbar />
      <div className="contact-cards-wrapper">
        <div className="contact-cards-container">
          <div className="contact-cards">
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Contact Us</h2>
                <div className="cpcs-contact-details">
                  <p>Email: info@mybusiness.com</p>
                  <p>Phone: 123-456-7890</p>
                </div>
                <div className="cpcs-social-media">
                  <h2>Social Media</h2>
                  <p>Check us out on social media</p>
                  <div className="cpcs-social-icons">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-twitter"></i>
                    </a>
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Leave a Message</h2>
                <form>
                  <input type="text" placeholder="Name" />
                  <input type="email" placeholder="Email" />
                  <textarea placeholder="Message"></textarea>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
