import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function Dozer() {
  return (
    <>
      <Helmet>
        <title>
          Crawler Tractor & Dozer Operation Assessment | Alpha Constructions
        </title>
        <meta
          name="description"
          content="Learn to operate crawler tractors and dozers safely with Alpha Constructions' NPORS assessment. This course includes theory and practical training in excavation, grading, and handling various terrain."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>Crawler Tractor & Dozer Operation Assessment</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for operators of all skill levels, from
              novice to experienced. It is based on NPORS learning outcomes and
              combines both theory and practical training. The course will equip
              delegates with the knowledge and skills needed to operate crawler
              tractors and dozers safely and efficiently, while adhering to best
              practices and legal requirements.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Roles and responsibilities of a plant operator in a construction
                environment.
              </li>
              <li>
                Identification of key components, their functions, construction,
                controls, and terminology of crawler tractors and dozers.
              </li>
              <li>
                Understanding manufacturer’s requirements and operator’s
                handbook guidelines, as well as relevant regulations and
                legislation.
              </li>
              <li>
                Performing pre-use checks for crawler tractors and dozers to
                ensure safe operation.
              </li>
              <li>
                Configuring and preparing equipment for safe travel on-site,
                including travelling over rough, undulating ground, inclines,
                and level surfaces.
              </li>
              <li>
                Manoeuvring the equipment in confined spaces and ensuring safety
                during operations.
              </li>
              <li>
                Setting the machine up for excavation duties and handling
                various types of ground safely.
              </li>
              <li>
                Recognizing hazards such as underground and overhead services.
              </li>
              <li>
                Constructing ramps, forming stockpiles, grading, spreading, and
                levelling materials.
              </li>
              <li>
                Ensuring safe shutdown and securing of equipment after use.
              </li>
              <li>
                Following correct loading and unloading procedures for
                transportation of crawler tractors and dozers.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment consists of both theory and practical components.
              The theory assessment includes multiple-choice questions, while
              the practical assessment will evaluate the operator’s ability to
              safely and efficiently operate the crawler tractor and dozer.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, participants will be
              eligible for the NPORS Traditional Card. This card does not
              require an NVQ qualification, but if applicants want the 'HS&E
              TESTED' logo on their card, they must pass the CITB Health,
              Safety, and Environment Test within two years prior to
              application. The NPORS card is valid for 3 or 5 years.
            </p>
            <p>
              For operators without the required NVQ qualification, a Trained
              Operator card will be issued. This card is valid for two years,
              during which time operators must complete the necessary NVQ
              qualification to upgrade to a Competent Operator card, valid for 5
              years.
            </p>
            <p>
              Operators who already hold the required NVQ and successfully
              complete the course will receive a Competent Operator card, valid
              for 5 years. A CPD record/operator logbook will also be provided,
              documenting the operator’s ongoing professional development and
              work experience.
            </p>
            <p>
              All applicants must provide proof of having passed the CITB
              Health, Safety, and Environment Test within the last two years at
              the time of application.
            </p>
            <p>
              NPORS cards can be issued with individual expiry dates for each
              category or with a single expiry date for all categories.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course, lasting from two to ten days depending on experience,
              provides essential training for operating crawler tractors and
              dozers safely. With a blend of theoretical learning and practical
              exercises, operators will gain the skills needed to navigate rough
              terrain, perform excavation tasks, and handle construction sites
              efficiently and safely.
            </p>
            <p>
              After completing the course and passing the assessments, operators
              will receive an NPORS certification, demonstrating their
              competence in crawler tractor and dozer operation.
            </p>
          </div>
          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
