import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Cpcs from "./pages/cpcs";
import Nvq from "./pages/nvq";
import Npors from "./pages/npors";
import AgriculturalTractor from "./pages/agriculturaltractor";
import CableAvoidance from "./pages/cableavoidance";
import ChainsawAssessment from "./pages/chainsawoperation";
import Crusher from "./pages/crusher";
import Dozer from "./pages/dozer";
import Excavator180 from "./pages/excavator180";
import Excavator360 from "./pages/excavator360";
import ContactUs from "./pages/contactus";
import ExcavatorCrane from "./pages/excavatorcrane";
import ExcavatorMicro from "./pages/excavatormicro";
import ForwardTippingDumper from "./pages/forwardtippingdumper";
import ForwardTippingMiniDumper from "./pages/forwardtippingminidumper";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cpcs" element={<Cpcs />} />
        <Route path="/npors" element={<Npors />} />
        <Route path="/nvq" element={<Nvq />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/agriculturaltractor" element={<AgriculturalTractor />} />
        <Route path="/cableavoidance" element={<CableAvoidance />} />
        <Route path="/chainsawoperation" element={<ChainsawAssessment />} />
        <Route path="/crusher" element={<Crusher />} />
        <Route path="/dozer" element={<Dozer />} />
        <Route path="/excavator180" element={<Excavator180 />} />
        <Route path="/excavator360" element={<Excavator360 />} />
        <Route path="/excavatorcrane" element={<ExcavatorCrane />} />
        <Route path="/excavatormicro" element={<ExcavatorMicro />} />
        <Route
          path="/forwardtippingdumper"
          element={<ForwardTippingDumper />}
        />
        <Route
          path="/forwardtippingminidumper"
          element={<ForwardTippingMiniDumper />}
        />
      </Routes>
    </Router>
  );
}

export default App;
