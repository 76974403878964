import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function Excavator360() {
  return (
    <>
      <Helmet>
        <title>N016 Excavator 360 Operator Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Get certified in Excavator 360 operation through Alpha Constructions' NPORS-accredited training. This course combines theory and hands-on practice to ensure safe and proficient machine handling."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N016 Excavator 360 Operator Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course aims to provide operators with the knowledge and
              skills needed to safely and effectively operate an Excavator 360.
              Designed to meet NPORS standards, the training combines
              theoretical knowledge with hands-on practice, ensuring
              participants gain a solid understanding of best practices, safety
              protocols, and machine handling techniques.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Develop awareness of industry hazards and the responsibilities
                of a plant operator.
              </li>
              <li>
                Gain familiarity with the manufacturer's handbook for the
                excavator model being used.
              </li>
              <li>
                Identify major components of the machine and understand their
                functions.
              </li>
              <li>
                Locate and understand the purpose of steering, driving, and
                braking controls.
              </li>
              <li>Choose and maintain appropriate PPE for excavator use.</li>
              <li>
                Perform all pre-operational checks according to manufacturer and
                legal guidelines.
              </li>
              <li>Safely mount and dismount the excavator.</li>
              <li>
                Start, stop, and maneuver the machine safely, adapting to
                different terrains and confined spaces.
              </li>
              <li>Conduct site safety checks before beginning work.</li>
              <li>Master excavation techniques for various scenarios.</li>
              <li>
                Load materials into transport vehicles or storage containers
                safely and efficiently.
              </li>
              <li>
                Restore excavated areas by grading and leveling to original
                contours.
              </li>
              <li>Attach and remove equipment attachments as required.</li>
              <li>Complete end-of-shift and shutdown procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes an NPORS theory test with multiple-choice
              questions and a practical assessment to verify each operator’s
              competency in real-world settings.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>Traditional Card:</strong> The NPORS Traditional Card does
              not require an NVQ qualification. If the ‘HS&E TESTED Logo’ is
              required, candidates must pass the CITB Health, Safety, and
              Environment Test within two years of application. This card is
              valid for either 3 or 5 years.
            </p>
            <p>
              <strong>NPORS/CPCS (No NVQ):</strong> Operators without the NVQ
              will receive a Trained Operator card valid for 2 years. During
              this period, they must complete the NVQ. Upon completion, they are
              eligible for a Competent Operator card, valid for 5 years, and
              will also receive a CPD logbook.
            </p>
            <p>
              <strong>NPORS/CPCS (NVQ Held):</strong> Operators with the NVQ
              will receive a Competent Operator card, valid for 5 years. They
              will also receive a CPD logbook to record their professional
              development and work hours.
            </p>
            <p>
              Proof of passing the CITB Health, Safety & Environment Test within
              the last two years is required for all certification applications.
            </p>
            <p>
              <strong>NPORS Card Expiry Options:</strong> NPORS cards can have
              separate expiry dates for each category, or a single expiry for
              all held categories.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This comprehensive 10-day course provides essential training for
              safe and effective operation of Excavator 360 machinery. Combining
              theory and hands-on practice, participants develop the skills
              necessary for efficient machine handling and workplace safety.
              Successful participants will be eligible for NPORS certification,
              valid for up to 5 years.
            </p>
          </div>

          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
