import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/npors.css";
import { Helmet } from "react-helmet";

export default function Npors() {
  return (
    <>
      <Helmet>
        <title>NPORS Training & Assessments | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about NPORS certification for plant operators in the UK at Alpha Constructions. Our NPORS training and assessments cover various machinery categories, including cranes, earthmoving, material handling, and demolition equipment. Find out the requirements and assessment details."
        />
      </Helmet>
      <Navbar />
      <div className="npors-container">
        <div className="npors-hero">
          <h1>NPORS Training & Assessments</h1>
        </div>

        <div className="npors-content">
          <div className="npors-info">
            <div className="npors-description">
              <h2>What is NPORS?</h2>
              <p>
                The National Plant Operators Registration Scheme (NPORS) is a
                widely recognized accreditation for plant operators in the UK.
                It offers flexible training and assessment solutions that meet
                the requirements of both employers and individuals within the
                construction and plant industries.
              </p>
              <p>
                We provide NPORS-certified training courses and competitive
                rates, with discounts available for multiple course
                registrations.
              </p>
            </div>
          </div>

          <div className="npors-requirements">
            <div className="requirements-content">
              <h2>Requirements</h2>
              <p>
                Candidates need a CITB Health & Safety certificate before
                undertaking NPORS training or assessments. This ensures they
                meet baseline health and safety standards for the construction
                industry.
              </p>
              <p>
                Without this certification, candidates cannot qualify for NPORS
                accreditation.
              </p>
            </div>

            <div className="assessment-info">
              <h2>About the NPORS Assessment</h2>
              <h3 className="assessment-heading">NPORS Theory Test</h3>
              <p>
                The NPORS theory test evaluates understanding of the machinery
                and industry regulations. It is typically a verbal test with a
                passing mark of 80%.
              </p>
              <h3 className="assessment-heading">NPORS Practical Test</h3>
              <p>
                The practical test includes exercises that assess a candidate's
                skill in safe machine operation, as well as health and safety
                compliance. Exercises cover setup, operation, and shutdown
                procedures. Duration varies based on the equipment.
              </p>
            </div>
          </div>

          <div className="npors-categories">
            <div className="categories-list">
              <div className="category">
                <h2>NPORS Categories</h2>
                <ul>
                  <li>Agricultural Tractor</li>
                  <li>Cable Avoidance Tools</li>
                  <li>Chain Saw - Maintenance & Cross Cutting</li>
                  <li>Crusher</li>
                  <li>Dozer</li>
                  <li>Excavator 180°</li>
                  <li>Excavator 360°</li>
                  <li>Excavator as a Crane</li>
                  <li>Excavator Micro (Up to 1 Tonne)</li>
                  <li>Forward Tipping Dumper</li>
                  <li>Forward Tipping Mini Dumper</li>
                  <li>Gritter & Snowplough</li>
                  <li>Harness and Fall Arrest Course</li>
                  <li>Industrial Counterbalance Lift Truck</li>
                  <li>Loading Shovel</li>
                  <li>Log Handler</li>
                  <li>Lorry Loader</li>
                  <li>M.E.W.P. Boom</li>
                  <li>M.E.W.P. Scissor Lift</li>
                  <li>Marine Knuckle Boom Crane</li>
                  <li>Material Re-Handler 360°</li>
                  <li>Paver</li>
                  <li>Plant Loader & Securer</li>
                  <li>Plant Machinery Marshal</li>
                  <li>Plant Mover</li>
                  <li>Quick Hitch Awareness</li>
                  <li>Rear Dump Truck</li>
                  <li>Road Planer</li>
                  <li>Road Roller</li>
                  <li>Road Sweeper</li>
                  <li>Safe Working At Height</li>
                  <li>Safety Awareness</li>
                  <li>Screener</li>
                  <li>Slinger/Signaller</li>
                  <li>Telescopic Handler</li>
                  <li>Telescopic Handler - Suspended Loads</li>
                  <li>Vehicle Marshal</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
