import { useEffect, useRef, useState } from "react";
import "../css/navbar.css";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <h2>
        <a href="/" className="nav-heading">
          Alpha Construction Training
        </a>
      </h2>

      <div className={`nav-links ${isOpen ? "open" : ""}`} ref={menuRef}>
        <a href="/cpcs">CPCS</a>
        <a href="/npors">NPORS</a>
        <a href="/nvq">NVQ</a>
        <a href="/contactus">Contact Us</a>
      </div>

      <div className="burger-icon " onClick={toggleMenu}>
        ☰
      </div>
    </nav>
  );
}
