import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/cpcs.css";
import { Helmet } from "react-helmet";

export default function Cpcs() {
  return (
    <>
      <Helmet>
        <title>CPCS Training & Assessments | Alpha Constructions</title>
        <meta
          name="description"
          content="Explore comprehensive CPCS training and assessment options at Alpha Constructions. Learn about the Construction Plant Competence Scheme (CPCS), requirements, theory and practical assessments, and various machinery categories for plant operators across the UK."
        />
      </Helmet>
      <Navbar />
      <div className="cpcs-container">
        <div className="cpcs-hero">
          <h1>CPCS Training & Assessments</h1>
        </div>
        <div className="cpcs-content">
          <div className="cpcs-info">
            <div className="cpcs-description">
              <h2>What is CPCS?</h2>
              <p>
                The Construction Plant Competence Scheme (CPCS) is a card-based
                scheme aimed at verifying the skill and competency of plant
                operators across the UK. As the largest recognized card scheme
                for the plant industry, CPCS helps to demonstrate operating
                skills as well as health and safety awareness.
              </p>
              <p>
                Our courses are competitively priced, and we offer discounts for
                multiple tests.
              </p>
            </div>
          </div>
          <div className="cpcs-requirements">
            <div className="requirements-content">
              <h2>Requirements</h2>
              <p>
                To qualify for the CPCS scheme, candidates must hold CITB Health
                & Safety Certification before beginning any training or
                assessments. This certification ensures a baseline of health and
                safety awareness in the industry.
              </p>
              <p>
                The CITB Health & Safety Test is essential to the qualification
                process. Without this certification, candidates cannot proceed
                with the CPCS Card and Schemes.
              </p>
            </div>
            <h2 className="assessment-heading">About the CPCS Assessment</h2>
            <div className="assessment-info">
              <h3>CPCS Theory Test</h3>
              <p>
                The theory test is a verbal recording, with a maximum time of 1
                hour. Candidates who require an interpreter will have 2 hours.
                The test assesses knowledge of the machine you are being tested
                on, with a required passing mark of 80%.
              </p>

              <h3 className="assessment-heading">CPCS Practical Test</h3>
              <p>
                The CPCS practical test consists of a series of exercises
                designed to evaluate a candidate's skill in operating machinery
                according to CPCS standards. These exercises include pre-start
                checks, traveling, setup for work, operating, and shutdown
                procedures. Candidates are also required to adhere to health and
                safety regulations for the equipment and its operation. The time
                allowed for each practical test varies based on the type of
                assessment.
              </p>
            </div>
          </div>
          <div className="cpcs-categories">
            <h2>CPCS Categories</h2>
            <div className="categories-list">
              <div className="category">
                <h3>Cranes and Lifting Equipment</h3>
                <ul>
                  <li>Tower Crane</li>
                  <li>Mobile Crane</li>
                  <li>Crawler Crane</li>
                  <li>Compact Crane</li>
                </ul>
              </div>

              <div className="category">
                <h3>Earthmoving Equipment</h3>
                <ul>
                  <li>Excavators (360° above and below 10 tonnes)</li>
                  <li>Forward Tipping Dumper</li>
                  <li>Rear Tipping Dumper</li>
                  <li>Loading Shovel</li>
                </ul>
              </div>

              <div className="category">
                <h3>Material Handling</h3>
                <ul>
                  <li>Telescopic Handler (Standard and 360° Slew)</li>
                  <li>Industrial Forklift</li>
                  <li>Sideloader</li>
                </ul>
              </div>

              <div className="category">
                <h3>Road and Paving Equipment</h3>
                <ul>
                  <li>Ride-On Roller</li>
                  <li>Paving Machine</li>
                  <li>Road Planer</li>
                </ul>
              </div>

              <div className="category">
                <h3>Demolition Equipment</h3>
                <ul>
                  <li>Demolition Plant</li>
                  <li>Demolition Skid Steer Loader</li>
                </ul>
              </div>

              <div className="category">
                <h3>Access Platforms</h3>
                <ul>
                  <li>Mobile Elevating Work Platform (MEWP) Boom</li>
                  <li>MEWP Scissor</li>
                </ul>
              </div>

              <div className="category">
                <h3>Specialist Plant</h3>
                <ul>
                  <li>Piling Rig (Driven and Rotary)</li>
                  <li>Trencher</li>
                  <li>Crusher</li>
                </ul>
              </div>

              <div className="category">
                <h3>Utilities and Highway Maintenance</h3>
                <ul>
                  <li>Road Roller</li>
                  <li>Trencher</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
