import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function Crusher() {
  return (
    <>
      <Helmet>
        <title>N207 Crusher Operation Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn to operate crushers safely and efficiently with Alpha Constructions' NPORS assessment. This course offers hands-on training in safe crusher operation, pre-operational checks, and environmental considerations."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N207 Crusher Operation Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is suitable for operators of all skill levels. Whether
              you are an experienced operator or new to crusher operations, this
              course is designed to provide comprehensive training based on
              NPORS learning outcomes. It combines both theoretical knowledge
              and practical tuition to ensure a thorough understanding of safe
              crusher operation and best practices.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the plant operation industry and its inherent
                risks.
              </li>
              <li>
                Working knowledge of the manufacturer’s manual for the specific
                machine.
              </li>
              <li>
                Identifying and explaining the functions of major crusher
                components.
              </li>
              <li>Using remote control systems to operate crushers safely.</li>
              <li>
                Performing pre-operational checks based on manufacturer and
                legal requirements.
              </li>
              <li>
                Ensuring proper use and maintenance of appropriate personal
                protective equipment (PPE).
              </li>
              <li>
                Correct mounting and dismounting techniques for the crusher.
              </li>
              <li>
                Starting and stopping the engine, belts, feeder, and jaws/hammer
                safely.
              </li>
              <li>
                Setting up the machine for travel and operating it across
                various terrains.
              </li>
              <li>
                Conducting safety checks on the worksite before starting the
                crushing tasks.
              </li>
              <li>
                Understanding different types of crushers and their advantages,
                disadvantages, and usage.
              </li>
              <li>
                Carrying out emergency stops and applying safety precautions
                during operations.
              </li>
              <li>
                Performing crushing tasks with a focus on safety and efficiency.
              </li>
              <li>
                Knowledge of loading and unloading procedures for crusher
                transportation.
              </li>
              <li>Considering environmental factors during operation.</li>
              <li>
                Following end-of-shift procedures and safely shutting down the
                equipment.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment will consist of both theoretical and practical
              components. The theory test includes multiple-choice questions,
              while the practical assessment involves demonstrating safe and
              effective crusher operation in a work environment.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will be eligible for the
              NPORS Traditional Card. This card does not require an NVQ
              qualification, but applicants must have passed the CITB Health,
              Safety, and Environment Test within two years if they want the
              ‘HS&E TESTED’ logo on the card. The NPORS card is valid for 3 or 5
              years.
            </p>
            <p>
              New entrants who do not hold the necessary NVQ qualification will
              receive a Trained Operator card, valid for 2 years. During this
              time, they will need to complete the NVQ requirements to upgrade
              to a Competent Operator card, which will be valid for 5 years.
            </p>
            <p>
              Operators who already possess the required NVQ and pass the
              assessment will be issued a Competent Operator card valid for 5
              years.
            </p>
            <p>
              All applicants must provide proof of passing the CITB Health,
              Safety, and Environment Test within the last two years at the time
              of application.
            </p>
            <p>
              NPORS cards can be issued with an individual expiry date for each
              category or a unified expiry date for all categories.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course, which can be completed in one to three days depending
              on individual requirements, provides participants with the
              knowledge and skills to operate crushers safely and efficiently.
              It combines theory with practical experience, ensuring a
              well-rounded understanding of all aspects of crusher operation.
            </p>
            <p>
              After completing the course and passing the assessment,
              participants will receive an NPORS certification, demonstrating
              their ability to work safely and competently as a crusher
              operator.
            </p>
          </div>
          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
