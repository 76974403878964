import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function ForwardTippingDumper() {
  return (
    <>
      <Helmet>
        <title>N204 Forward Tipping Dumper Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified training for Forward Tipping Dumper operation. Learn the skills for safe and efficient dumper use on site."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N204 Forward Tipping Dumper Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to train operators in the safe and
              efficient use of Forward Tipping Dumpers. It covers both
              theoretical and practical knowledge to ensure operators can safely
              operate the machine on various terrains, follow best practices for
              loading and unloading, and eliminate any unsafe habits. The course
              adheres to NPORS standards, providing participants with the
              competency to safely operate dumpers on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>Carrying out pre-start and running checks.</li>
              <li>Setting the dumper for site and road travel.</li>
              <li>
                Travelling over different types of terrain, including rough,
                undulating ground (both loaded and unloaded).
              </li>
              <li>
                Travelling up and down substantial inclines (both loaded and
                unloaded).
              </li>
              <li>Positioning the dumper to receive loads.</li>
              <li>Complying with loading procedures.</li>
              <li>Discharging loads into trenches and over edges.</li>
              <li>Placing the machine in and out of service condition.</li>
              <li>
                Explaining loading and unloading procedures on and off of
                transporters.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Delegates will undergo a theory assessment, which includes
              multiple-choice questions, followed by a practical test to assess
              their skills in operating the dumper, handling loads, and
              executing tasks safely.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS Traditional Card:</strong> This certification is
              valid for 3 or 5 years, and is recognized across various sectors.
              It does not require an NVQ qualification.
            </p>
            <p>
              <strong>NPORS with CSCS Logo:</strong> For operators who require
              the CITB Health, Safety, and Environment Test. The RED Trained
              Operator card is valid for 2 years, and can be upgraded to the
              BLUE Competent Operator card upon NVQ completion.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N204 Forward Tipping Dumper course provides operators with
              essential skills for handling dumpers safely on various terrains.
              It blends theoretical and practical training, ensuring competence
              in operations such as load handling, site navigation, and machine
              maintenance. The course is aimed at plant operators seeking NPORS
              certification, with the opportunity to upgrade to a Competent
              Operator card after completing NVQ requirements.
            </p>
          </div>

          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
