import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function ChainsawAssessment() {
  return (
    <>
      <Helmet>
        <title>
          N602 Chainsaw Operation Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Master chainsaw operation with Alpha Constructions. This 2-day NPORS assessment provides both theory and practical experience in safe and effective chainsaw use."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N602 Chainsaw Operation Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is suitable for operators of all skill levels, whether
              experienced or new to chainsaw operation. The course is structured
              around NPORS learning outcomes and combines both theoretical
              knowledge and practical tuition to equip delegates with a strong
              understanding of best practices and safe operation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>Introduction to chainsaw operation and safety.</li>
              <li>Understanding relevant HSE legislation and guidelines.</li>
              <li>
                Overview of the different types and components of a chainsaw.
              </li>
              <li>
                Personal protective equipment (PPE) required for chainsaw
                operation.
              </li>
              <li>
                Maintaining your chainsaw and keeping it in working order.
              </li>
              <li>
                Proper maintenance of the chainsaw guide bar and power unit.
              </li>
              <li>Safe and correct starting procedures for the chainsaw.</li>
              <li>Pre-cutting tests to ensure chainsaw readiness.</li>
              <li>Techniques for cross-cutting timber safely.</li>
              <li>
                Course assessment to evaluate theoretical and practical skills.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment includes both a theory test with multiple-choice
              questions and a practical evaluation, where participants will
              demonstrate their skills in operating the chainsaw and performing
              the required tasks.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants may apply for the NPORS
              Traditional Card. There is no requirement for an NVQ qualification
              for this card, but applicants must pass the CITB Health, Safety &
              Environment Test within the two years prior to applying if they
              wish to have the ‘HS&E TESTED’ logo on the card. The NPORS card is
              valid for 3 to 5 years.
            </p>
            <p>
              For new entrants without an NVQ, a Trained Operator card valid for
              2 years will be issued. During this period, operators will need to
              complete the necessary NVQ qualifications. Once this is completed,
              they will receive a Competent Operator card valid for 5 years.
            </p>
            <p>
              Operators who already hold the required NVQ and successfully
              complete the assessment will be issued with a Competent Operator
              card valid for 5 years.
            </p>
            <p>
              All operators must provide proof of having passed the CITB Health,
              Safety & Environment Test within the last two years at the time of
              applying for their NPORS card.
            </p>
            <p>
              NPORS cards can be issued with an individual expiry date for each
              category or one expiry date for all categories held.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This 2-day course ensures that participants gain essential skills
              and knowledge to operate chainsaws safely and effectively. The
              course combines practical hands-on experience with theoretical
              learning, allowing participants to confidently meet industry
              standards.
            </p>
            <p>
              After completing the course and assessments, participants will be
              awarded an NPORS certification, demonstrating their competency in
              chainsaw operation.
            </p>
          </div>
          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
