import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function AgriculturalTractor() {
  return (
    <>
      <Helmet>
        <title>N601 Agricultural Tractor Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about Agricultural Tractor training with NPORS certification at Alpha Construction Training. Gain skills for safe operation, industry compliance, and receive recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N601 Agricultural Tractor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to equip operators with the essential
              skills and knowledge needed to safely and efficiently operate an
              agricultural tractor. By the end of the training, participants
              will have a strong understanding of industry standards, safety
              procedures, and operational best practices.
            </p>
            <p>
              NPORS certification is highly valued across various industries,
              including construction, utilities, and agriculture, and provides
              operators with a widely recognized qualification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                An understanding of the agricultural plant operation industry
                and the key safety practices.
              </li>
              <li>
                Knowledge of the roles and responsibilities of a plant operator.
              </li>
              <li>
                Comprehensive safety training, focusing on hazard awareness and
                safe working procedures.
              </li>
              <li>
                Familiarity with the latest legal regulations governing plant
                operations and safety standards.
              </li>
              <li>
                Introduction to tractor controls, systems, and pre-shift safety
                checks.
              </li>
              <li>
                Basic maintenance procedures, such as managing fuel, coolant,
                and lubrication.
              </li>
              <li>
                Detailed instruction on tractor mechanisms and controls to
                ensure proper operation.
              </li>
              <li>
                Techniques for loading, unloading, and maneuvering with
                trailers.
              </li>
              <li>How to inspect and use mounted implements safely.</li>
              <li>
                Practical hands-on sessions to reinforce skills and build
                confidence in operating agricultural tractors safely.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment includes a theory test (25 multiple-choice
              questions) that evaluates your knowledge of tractor operations and
              safety. In addition, there is a practical assessment where your
              hands-on proficiency will be tested.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon completion, participants can choose the NPORS-CSCS route and
              receive a Red Trained Operator card valid for 2 years. If they
              hold a relevant NVQ, they can upgrade to a Blue Competent Operator
              card, which is valid for 5 years. Those who do not pass the CITB
              Health, Safety & Environment test may receive a traditional 3-year
              NPORS card but without CSCS recognition.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course provides a well-rounded combination of theoretical
              instruction and practical hands-on training, fully preparing you
              for the NPORS assessment. Additional study materials are provided
              to help participants who are new to Agricultural Tractor
              operation.
            </p>
            <p>
              Led by experienced instructors, the course emphasizes practical
              skills and theoretical knowledge, ensuring that you leave with the
              confidence and competence to safely operate an agricultural
              tractor.
            </p>
          </div>
          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
