import "../css/footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Alpha Constructions. All rights reserved.</p>
      <p>Contact us: info@mybusiness.com | Follow us on social media</p>
    </footer>
  );
}
