// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #333;
  color: #f2f2f2;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #f2f2f2;
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  color: #ffd700; /* Optional hover color */
}

@media (max-width: 600px) {
  .footer {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,cAAc,EAAE,yBAAyB;AAC3C;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".footer {\n  background-color: #333;\n  color: #f2f2f2;\n  padding: 20px;\n  text-align: center;\n  font-size: 14px;\n}\n\n.footer p {\n  margin: 5px 0;\n}\n\n.footer a {\n  color: #f2f2f2;\n  text-decoration: none;\n  margin: 0 5px;\n}\n\n.footer a:hover {\n  color: #ffd700; /* Optional hover color */\n}\n\n@media (max-width: 600px) {\n  .footer {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
