import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function ForwardTippingMiniDumper() {
  return (
    <>
      <Helmet>
        <title>
          N139 Forward Tipping Mini Dumper Course | Alpha Constructions
        </title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified training for Forward Tipping Mini Dumper operation. Gain essential skills for safe and efficient dumper use on site."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N139 Forward Tipping Mini Dumper Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to train operators in the safe and
              efficient use of Forward Tipping Mini Dumpers. It covers both
              theoretical and practical aspects of operating the machine
              according to NPORS standards. Participants will learn the
              essential skills required for safe operation, including handling,
              safety checks, and industry best practices.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the mini dumper, controls, required PPE, and
                safe mounting/dismounting practices.
              </li>
              <li>
                Conducting pre-start and running checks, and understanding the
                major components, features, benefits, and associated risks.
              </li>
              <li>
                Understanding the general responsibilities of the operator and
                referring to the manufacturer's handbook for the specific
                machine in use.
              </li>
              <li>
                Operating procedures in line with industry best practices and
                NPORS guidelines.
              </li>
              <li>
                Safe parking and shutdown procedures to ensure safe operation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Delegates will take a multiple-choice theory test (25 questions)
              followed by a practical test to assess their operational skills
              and understanding of safety protocols.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS Traditional Card:</strong> This card is valid for
              3-5 years and is the internationally recognized standard NPORS
              card.
            </p>
            <p>
              <strong>CSCS Embossed NPORS Card:</strong> This version of the
              NPORS card, embossed with the CSCS logo, is widely recognized
              within the UK construction industry. To obtain this card, trainees
              must pass the CITB Health, Safety, and Environment Test within 2
              years of applying for the NPORS ticket.
            </p>
            <p>
              <strong>Types of NPORS CSCS Embossed Cards:</strong>
              <ul className="no-bullets">
                <li>
                  <strong>
                    NPORS CSCS Red Trained Operator Card (2 years):
                  </strong>{" "}
                  Issued after completing the training and/or NPORS test with a
                  valid HS&E Health, Safety & Environment test.
                </li>
                <li>
                  <strong>
                    NPORS CSCS Blue Competent Operator Card (5 years):
                  </strong>
                  Available for operators who have completed NPORS
                  training/testing and hold the relevant NVQ.
                </li>
              </ul>
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N139 Forward Tipping Mini Dumper Course equips operators with
              the skills to safely and efficiently operate mini dumpers in line
              with industry standards. The course covers machine operation,
              safety checks, and best practices, culminating in an NPORS
              certification. Operators can upgrade to a Blue Competent Operator
              card upon completing the NVQ requirements.
            </p>
          </div>

          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
