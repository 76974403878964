import "../css/home.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import npors from "../images/npors.png";
import nvq from "../images/nvq.jpg";
import cpcs from "../images/cpcs.webp";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
export default function Home() {
  const [displayedText, setDisplayedText] = useState("");
  const fullText = "Welcome to Alpha Construction Training";

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index <= fullText.length) {
        setDisplayedText(fullText.substring(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Home | Alpha Constructions</title>
        <meta
          name="description"
          content="Alpha Constructions offers expert guidance and training for CPCS, NPORS, and NVQ certifications. Reach out to us to build your skills and advance your career with trusted qualifications in construction. Connect with us via email, phone, social media, or our contact form."
        />
      </Helmet>
      <Navbar />
      <div className="hero">
        <h1>{displayedText}</h1>
      </div>

      <div className="cards-wrapper">
        <div className="cards-container">
          <div className="cards">
            <div className="card">
              <img src={cpcs} alt="CPCS card information" />
              <div className="card-content">
                <h2>CPCS</h2>
                <p>
                  Provides essential certifications for operators of plant
                  machinery, ensuring safety and compliance in construction.
                </p>
                <a href="https://www.alphacontraining.co.uk/cpcs">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="card">
              <img src={npors} alt="NPORS card information" />
              <div className="card-content">
                <h2>NPORS</h2>
                <p>
                  An alternative to CPCS, focusing on flexibility and quality in
                  machinery operation training and accreditation.
                </p>
                <a href="https://www.alphacontraining.co.uk/npors">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="card">
              <img src={nvq} alt="NVQ card information" />
              <div className="card-content">
                <h2>NVQ</h2>
                <p>
                  Recognizes on-the-job skills and knowledge across various
                  construction roles, qualifying professionals for career
                  advancement.
                </p>
                <a href="https://www.alphacontraining.co.uk/nvq">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-cards-wrapper">
        <div className="contact-cards-container">
          <div className="contact-cards">
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Contact Us</h2>
                <div className="cpcs-contact-details">
                  <p>Email: info@mybusiness.com</p>
                  <p>Phone: 123-456-7890</p>
                </div>
                <div className="cpcs-social-media">
                  <h2>Social Media</h2>
                  <p>Check us out on social media</p>
                  <div className="cpcs-social-icons">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-twitter"></i>
                    </a>
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Leave a Message</h2>
                <form>
                  <input type="text" placeholder="Name" />
                  <input type="email" placeholder="Email" />
                  <textarea placeholder="Message"></textarea>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
