import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/machine.css";
import { Helmet } from "react-helmet";

export default function Excavator180() {
  return (
    <>
      <Helmet>
        <title>N201 Excavator 180 Operator Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Gain certification in wheeled loading shovel operation with Alpha Constructions' NPORS training. This course offers comprehensive theoretical and practical training on safe and effective machine operation."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N201 Excavator 180 Operator Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is ideal for operators of all skill levels, whether
              they are new to operating 180-degree excavators or experienced
              professionals. The training is aligned with NPORS standards,
              combining theoretical knowledge with practical application to
              ensure participants gain a thorough understanding of best
              practices and safety protocols in excavator operation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the role and responsibilities of a plant operator
                within the industry.
              </li>
              <li>
                Recognize the purpose, components, controls, and terminology
                associated with wheeled loading shovels.
              </li>
              <li>
                Comply with manufacturer’s requirements, following the
                operator’s handbook and relevant regulations.
              </li>
              <li>
                Conduct all essential pre-use checks on wheeled loading shovels.
              </li>
              <li>
                Configure the plant for safe travel on both site and highways.
              </li>
              <li>
                Operate on various terrains, including rough ground, inclines,
                and level surfaces, both with and without loads.
              </li>
              <li>Safely maneuver in confined spaces.</li>
              <li>
                Prepare the loading shovel for excavation, loading, and handling
                duties.
              </li>
              <li>
                Identify and respond to hazards, including underground and
                overhead utilities.
              </li>
              <li>
                Extract materials from stockpiles and create segregated storage
                areas.
              </li>
              <li>
                Sort and place materials into transporting vehicles or hoppers.
              </li>
              <li>
                Level ground and materials according to project requirements.
              </li>
              <li>
                Apply safe shutdown and securing procedures for the equipment.
              </li>
              <li>
                Understand loading and unloading procedures for safe machine
                transportation.
              </li>
              <li>
                Carry out end-of-shift and shutdown procedures effectively.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes an NPORS theory assessment with
              multiple-choice questions and a practical evaluation to ensure
              operators can perform all necessary tasks safely and competently.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>Traditional Card:</strong> The NPORS Traditional Card does
              not require an NVQ qualification. However, if the ‘HS&E TESTED
              Logo’ is desired, candidates must have passed the CITB Health,
              Safety, and Environment Test within the last two years. The card
              is valid for 3 or 5 years.
            </p>
            <p>
              <strong>NPORS/CPCS (No NVQ):</strong> Operators who do not yet
              hold the relevant NVQ will receive a Trained Operator card valid
              for 2 years after testing. During this period, they must complete
              the required NVQ. Upon completion, a Competent Operator card,
              valid for 5 years, will be issued along with a CPD record/operator
              logbook.
            </p>
            <p>
              <strong>NPORS/CPCS (NVQ Held):</strong> Operators already holding
              the requisite NVQ will receive a Competent Operator card valid for
              5 years. They will also be issued a CPD record/operator logbook to
              document their professional development and working hours.
            </p>
            <p>
              Proof of passing the CITB Health, Safety & Environment Test within
              the past two years is required for all applications.
            </p>
            <p>
              <strong>NPORS Card Expiry Options:</strong> NPORS cards can
              feature individual expiry dates per category or a single
              expiration date for all categories held.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This five-day course provides essential training for operating
              wheeled loading shovels. Through a blend of theory and practice,
              operators develop the skills needed for safe machine handling and
              workplace efficiency. Upon successful course completion, operators
              will be eligible for NPORS certification, valid for up to 5 years.
            </p>
          </div>

          <div className="contact-us-section">
            <button className="contact-us-button">Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
