import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/nvq.css";
import { Helmet } from "react-helmet";

export default function Nvq() {
  return (
    <>
      <Helmet>
        <title>NVQ Training & Assessments | Alpha Constructions</title>
        <meta
          name="description"
          content="Discover NVQ certification options at Alpha Constructions, designed to verify skills in construction and plant operations. Learn about NVQ requirements, theory and practical assessments, and various trade-specific categories for professional qualifications in the UK."
        />
      </Helmet>
      <Navbar />
      <div className="nvq-container">
        <div className="nvq-hero">
          <h1>NVQ Training & Assessments</h1>
        </div>

        <div className="nvq-content">
          <div className="nvq-info">
            <div className="nvq-description">
              <h2>What is NVQ?</h2>
              <p>
                The National Vocational Qualification (NVQ) is a recognized
                certification in the UK that verifies a candidate’s practical
                skills and knowledge within a specific trade or industry. It
                offers structured, work-based assessments that are highly
                regarded in the construction and plant sectors.
              </p>
              <p>
                We provide NVQ certification assessments at competitive rates,
                with discounts available for multiple course registrations.
              </p>
            </div>
          </div>

          <div className="nvq-requirements">
            <div className="requirements-content">
              <h2>Requirements</h2>
              <p>
                Candidates must hold a CITB Health & Safety certificate before
                undertaking NVQ assessments. This certificate ensures baseline
                health and safety knowledge required within the industry.
              </p>
              <p>
                Without this certification, candidates are not eligible for NVQ
                accreditation.
              </p>
            </div>
            <h2 className="assessment-heading">About the NVQ Assessment</h2>

            <div className="assessment-info">
              <h3>NVQ Theory Test</h3>
              <p>
                The NVQ theory test evaluates a candidate's understanding of
                industry regulations and best practices. The test may include
                questions on safety, equipment handling, and operational
                knowledge, with a minimum passing mark of 80%.
              </p>

              <h3 className="assessment-heading">NVQ Practical Test</h3>
              <p>
                The practical assessment involves a series of exercises designed
                to verify a candidate’s proficiency in performing job-specific
                tasks safely and efficiently. This includes machine setup,
                operation, and proper shutdown. The test duration may vary based
                on the level and category of the qualification.
              </p>
            </div>
          </div>

          <div className="nvq-categories">
            <h2>NVQ Categories</h2>
            <div className="categories-list">
              <div className="category">
                <h3>Construction Operations</h3>
                <ul>
                  <li>Bricklaying</li>
                  <li>Carpentry</li>
                  <li>Plastering</li>
                  <li>Flooring Installation</li>
                  <li>Formwork</li>
                  <li>Concrete Placement</li>
                </ul>
              </div>

              <div className="category">
                <h3>Plant Operations</h3>
                <ul>
                  <li>Excavator Operator (360° above and below 10 tonnes)</li>
                  <li>Loader Operator</li>
                  <li>Crane Operator (Mobile, Tower, Crawler)</li>
                  <li>Forklift Operator (Counterbalance, Reach, Telescopic)</li>
                  <li>Skid Steer Loader</li>
                  <li>Dozer Operator</li>
                  <li>Roller Operator</li>
                  <li>Dump Truck Operator</li>
                </ul>
              </div>

              <div className="category">
                <h3>Construction Site Supervision</h3>
                <ul>
                  <li>Health and Safety Supervision</li>
                  <li>Site Logistics</li>
                  <li>Quality Assurance</li>
                  <li>Team Management</li>
                </ul>
              </div>

              <div className="category">
                <h3>Technical Support</h3>
                <ul>
                  <li>Surveying</li>
                  <li>Site Engineering</li>
                  <li>Project Planning</li>
                  <li>Design and Setting Out</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
